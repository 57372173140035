import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AuthUser, UserRole } from '../../ts/interfaces/interfaces'

type InitialUserState = Omit<AuthUser, 'id' | 'token' | 'role'> & { id: number | null; token: string | null; role?: null | UserRole }

const initialUserJson: InitialUserState = {
  id: null,
  email: '',
  token: null,
  refresh_token: '',
  phone_numbers: [],
  role: null,
  name: '',
  tax_information: []
}

const initialState = localStorage.getItem('user') !== null ? (JSON.parse(localStorage.getItem('user') as string) as InitialUserState) : initialUserJson

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<Partial<AuthUser>>) => {
      localStorage.setItem('user', JSON.stringify(action.payload))
      return { ...state, ...action.payload }
    },
    signIn: (state, action: PayloadAction<Omit<AuthUser, 'tax_information'>>) => {
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(action.payload))
      return { ...state, ...action.payload }
    },
    logOut: () => {
      localStorage.removeItem('user')
      return initialUserJson
    },
    refreshToken: (state, action: PayloadAction<{ refresh_token: AuthUser['refresh_token']; token: AuthUser['token'] }>) => {
      const user = JSON.parse(localStorage.getItem('user') as string) as AuthUser

      user.refresh_token = action.payload.refresh_token
      user.token = action.payload.token

      localStorage.setItem('user', JSON.stringify(user))
      return { ...state, refresh_token: action.payload.refresh_token, token: action.payload.token }
    }
  }
})

export const { setUserDetails, logOut, signIn, refreshToken } = userSlice.actions

export default userSlice.reducer
