import { SignInBody, SignUpBody, ResetPasswordBody } from '../ts/interfaces/request'
import axios from 'axios'
import { store } from '../store'
import { uiSlice } from '../store/ui/slice'
import { UserRole } from '../ts/interfaces/interfaces'

export interface AuthUser {
  id: number
  email: string
  phone_numbers: string[]
  role: UserRole
  name?: string
  organization_id: number
}

export interface Session {
  access_token: string
  refresh_token: string
}

export interface AuthResponse {
  data: AuthUser
  session: Session
}

export const AuthService = () => ({
  async signUp(data: SignUpBody): Promise<AuthResponse> {
    try {
      const response = await axios.post<AuthResponse>(`${process.env.REACT_APP_ZUMMA_SERVICE_SERVICE_URL}/v1/auth/sign-up`, data)

      return response.data
    } catch (error) {
      throw error
    }
  },

  async signIn(data: SignInBody): Promise<AuthResponse> {
    try {
      const response = await axios.post<AuthResponse>(`${process.env.REACT_APP_ZUMMA_SERVICE_SERVICE_URL}/v1/auth/sign-in`, data)

      return response.data
    } catch (error) {
      throw error
    }
  },

  async sendResetPasswordEmail(email: string): Promise<any> {
    try {
      store.dispatch(uiSlice.actions.setIsFetching(true))
      const response = await axios.post<any>(`${process.env.REACT_APP_ZUMMA_SERVICE_SERVICE_URL}/v1/auth/reset-password-email`, { email })
      store.dispatch(uiSlice.actions.setIsFetching(false))
      return response
    } catch (error) {
      store.dispatch(uiSlice.actions.setIsFetching(false))
      throw error
    }
  },

  async resetPassword(body: ResetPasswordBody): Promise<AuthResponse | null> {
    try {
      store.dispatch(uiSlice.actions.setIsFetching(true))
      const response = await axios.post(`${process.env.REACT_APP_ZUMMA_SERVICE_SERVICE_URL}/v1/auth/reset-password`, body)
      store.dispatch(uiSlice.actions.setIsFetching(false))
      return response.data
    } catch (error) {
      store.dispatch(uiSlice.actions.setIsFetching(false))
      throw error
    }
  }
})
