import { RequestHandler, IRequestHandler } from '../lib/RequestHandler'
import { store } from '../store'
import { organizationSlice } from '../store/organization/slice'
import { UpdateUserForm, User, UserForm } from '../ts/interfaces/interfaces'
import { GetAllUsersResponse, CreateUserResponse, GetUserResponse, UpdateUserResponse, DeleteUserResponse, EnableUserResponse, GetAllUsersParams } from '../ts/interfaces/request'

export const UserService = (props?: IRequestHandler) => ({
  requestHandler: RequestHandler(props),

  async getAllUsers(params?: GetAllUsersParams): Promise<GetAllUsersResponse> {
    let searchParams: Record<string, string> = {}
    if (params) {
      if (params.filter_by) searchParams.filter_by = params.filter_by
      if (params.page && params.page_size) {
        searchParams.page = params.page.toString()
        searchParams.page_size = params.page_size.toString()
      }
    }

    try {
      const urlSearchParams = new URLSearchParams(searchParams)

      const response = await this.requestHandler.get<GetAllUsersResponse>(`/users/?${urlSearchParams}`)

      if (params?.setStateResult !== false && response && response.data && response.data.data) {
        store.dispatch(organizationSlice.actions.setOrganizationState({ users: response.data.data }))
      }
      if (response && response.data) return response.data
      return {
        data: [],
        total: 0
      }
    } catch (error) {
      throw error
    }
  },

  async getUser(id: User['id']) {
    try {
      const response = await this.requestHandler.get<GetUserResponse>(`/users/${id}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  },

  async createUser(body: UserForm): Promise<User> {
    try {
      const response = await this.requestHandler.post<CreateUserResponse>('/users/create', body)
      return response.data.data
    } catch (error) {
      throw error
    }
  },

  async updateUser(body: UpdateUserForm): Promise<User> {
    try {
      const response = await this.requestHandler.patch<UpdateUserResponse>(`/users/`, body)
      return response.data.data
    } catch (error) {
      throw error
    }
  },

  async deleteUser(userId: User['id']): Promise<User> {
    try {
      const response = await this.requestHandler.delete<DeleteUserResponse>(`/users/${userId}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  },

  async enableUser(userId: User['id']): Promise<User> {
    try {
      const response = await this.requestHandler.post<EnableUserResponse>(`/users/enable/${userId}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  }
})
