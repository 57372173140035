import { useFormik, FormikConfig, FormikValues } from 'formik'
import * as React from 'react'

interface UseFormConfig<Values extends FormikValues = FormikValues> extends FormikConfig<Values> {
  trimOnChange?: boolean
}

type HandleSelectValue = string | number | undefined | null | Array<string | number | undefined | null>
const useForm = <Values extends FormikValues = FormikValues>(
  props: UseFormConfig<Values>
): {
  handleFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSetTouchedForm?: any
  handleSelectChange: (name: string, value: HandleSelectValue) => void
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & ReturnType<typeof useFormik<Values>> => {
  const { trimOnChange = false, ...formikConfig } = props
  const form = useFormik(formikConfig)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    if (trimOnChange && typeof value === 'string') {
      value = value.trim()
    }
    event.target.value = value
    form.handleChange(event)
  }

  const handleSetTouchedForm = () => {
    for (const key in Object.keys(form.initialValues)) {
      form.setFieldTouched(key, true)
    }
  }

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldTouched(event.target.name, true)
  }

  const handleSelectChange = (name: string, value: HandleSelectValue) => {
    form.setFieldValue(name, value).then((re) => {
      form.validateField(name)
    })
  }

  return {
    ...form,
    handleSelectChange,
    handleFocus,
    handleSetTouchedForm,
    handleChange
  }
}

export { useForm }
