import React from 'react'
import { DataTable } from '../fragments/Table'
import { routerObj } from '../routing/Router'
import { Flex, IconButton, Tooltip, Text } from '@chakra-ui/react'
import { FaPencilAlt, FaTrash, FaTrashRestore } from 'react-icons/fa'
import { TAX_REGIMES } from './TaxInformationForm'
import { Buffer } from 'buffer'
import { createColumnHelper, CellContext } from '@tanstack/react-table'
import { TaxInformation } from '../../ts/interfaces/invoices'
import { useAppSelector } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'

export interface TableData extends Omit<TaxInformation, 'business_name' | 'first_name' | 'last_name' | 'surname' | 'middle_name'> {
  identification: string
  action?: string
}
const columnHelper = createColumnHelper<TableData>()

interface ITaxInformationTableProps {
  isLoading: boolean
  handleDeleteIconButton: (data: CellContext<TableData, string | undefined>) => void
}

const TaxInformationTable = React.memo(({ handleDeleteIconButton, isLoading }: ITaxInformationTableProps) => {
  const navigate = useNavigate()
  const taxInformationList = useAppSelector((state) => state.invoices.taxInformation)
  const tableData = React.useMemo(
    () =>
      taxInformationList.length
        ? taxInformationList.reduce((acc, item) => {
            const { business_name, first_name, surname } = item

            const identification = item.tax_id.length === 13 ? `${first_name} ${surname}` : business_name

            acc.push({
              identification,
              ...item
            })

            return acc
          }, [] as TableData[])
        : [],
    [taxInformationList]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('tax_id', {
        cell: (info) => <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{info.getValue()}</Text>,
        header: 'Registro Federal de Contribuyente (RFC)'
      }),
      columnHelper.accessor('identification', {
        cell: (info) => <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{info.getValue()}</Text>,
        header: 'Razón Social'
      }),
      columnHelper.accessor('postal_code', {
        cell: (info) => <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{info.getValue()}</Text>,
        header: 'Código Postal'
      }),

      columnHelper.accessor('tax_regime', {
        cell: (info) => <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{TAX_REGIMES.find(({ value }) => value === info.getValue())?.label}</Text>,
        header: 'Regimen fiscal'
      }),
      columnHelper.accessor('action', {
        cell: (data) => {
          return (
            <Flex columnGap='1rem'>
              <Tooltip label='Modificar'>
                <IconButton
                  id={`updateTaxInformationButton-${data.row.index}`}
                  onClick={() => {
                    navigate(`${routerObj.updateTaxInformation.cleanParamsPath}/${Buffer.from(data.row.original.id.toString()).toString('base64')}`)
                  }}
                  aria-label='row modify tax information'
                  icon={<FaPencilAlt />}
                />
              </Tooltip>

              <Tooltip label={data.row.original.deleted_at ? 'Habilitar' : 'Deshabilitar'}>
                <IconButton
                  onClick={() => handleDeleteIconButton(data)}
                  aria-label='row enable-disable tax information'
                  icon={data.row.original.deleted_at ? <FaTrashRestore /> : <FaTrash />}
                />
              </Tooltip>
            </Flex>
          )
        },
        header: 'Acciones'
      })
    ],
    [navigate, taxInformationList]
  )

  return (
    <DataTable
      isLoading={isLoading}
      data={tableData}
      columns={columns}
      total={tableData.length}
    />
  )
})

export { TaxInformationTable }
