'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { Avatar, Button, Flex, Icon, SimpleGrid, Text, useToast } from '@chakra-ui/react'
import { FaKey, FaPencilAlt } from 'react-icons/fa'
import { rounded, padding, containerCardStyles } from '../utils/ui'
import { routerObj } from '../components/routing/Router'
import { useAppSelector } from '../store/hooks'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { AuthService } from '../services/Auth'
import { UserSubscription } from '../components/ui/UserSubscription'
import { SendInvoiceButton } from '../components/ui/SendInvoiceButton'
import { UserRole } from '../ts/interfaces/interfaces'

const ProfilePage = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user)
  const handleSendRenewPasswordEmail = React.useCallback(async () => {
    try {
      await AuthService().sendResetPasswordEmail(user.email)
      toast({
        title: 'Email de cambio de contraseña enviado.',
        status: 'info',
        duration: 8000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Hubo un problema.',
        description: JSON.parse((error as AxiosError).request.response as string).message || '',
        status: 'error',
        duration: 8000,
        isClosable: true
      })
    }
  }, [AuthService, toast, user])

  return (
    <PageLayout>
      <Flex
        w='100%'
        mb='1.5rem'
        flexDir='column'
        alignItems='center'
        rowGap='.5rem'
      >
        <Avatar name={user.name || user.email} />
        <Text
          fontSize={{
            base: '1.2rem',
            md: '1.5rem'
          }}
        >
          {user.name}
        </Text>
        <Text
          fontSize={{
            base: '1.2rem',
            md: '1.5rem'
          }}
        >
          {user.email}
        </Text>
      </Flex>

      <SimpleGrid
        mb='1rem'
        columnGap='1rem'
        rowGap='1rem'
        columns={{
          base: 1,
          lg: 2
        }}
      >
        <Button
          onClick={handleSendRenewPasswordEmail}
          rounded={rounded.container}
          px={padding.button.px}
          py={padding.button.py}
          w='100%'
          justifyContent='space-between'
          {...containerCardStyles}
        >
          <Text fontSize='1.1rem'>Cambiar Contraseňa</Text>
          <Icon as={FaKey} />
        </Button>

        {user.role === UserRole.ADMIN ? (
          <Button
            onClick={() => {
              navigate(routerObj.taxInformationDashboard.path)
            }}
            rounded={rounded.container}
            px={padding.button.px}
            py={padding.button.py}
            w='100%'
            justifyContent='space-between'
            {...containerCardStyles}
          >
            <Text fontSize='1.1rem'>Modificar Información Fiscal</Text>
            <Icon as={FaPencilAlt} />
          </Button>
        ) : null}
      </SimpleGrid>

      {user.role === UserRole.ADMIN ? <UserSubscription /> : null}

      <SendInvoiceButton displayQRCode={true} />
    </PageLayout>
  )
}

export { ProfilePage }
