import React from 'react'
import { Router, routerObj, router } from './components/routing/Router'
import { Provider } from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource-variable/inter'
import { store } from './store'
import { InvoicesService } from './services/Invoices'
import { SubscriptionService } from './services/Subscription'
import { UserRole } from './ts/interfaces/interfaces'
function App() {
  const init = async () => {
    if (localStorage.getItem('user') && store.getState().user.role === UserRole.ADMIN && ![routerObj.signIn.path, routerObj.signUp.path, routerObj.renewPassword.cleanParamsPath, routerObj.recoverPassword.path].includes(router.state.location.pathname)) {
      await SubscriptionService({ useLoader: true }).getSubscriptionStatus()
      await InvoicesService({ useLoader: true }).getInvoicesStatus({ fetch_stats: false })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <ChakraProvider>
      <Provider store={store}>
        <Router />
      </Provider>
    </ChakraProvider>
  )
}

export default App
