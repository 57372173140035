import React, { useState } from 'react'
import { Input, InputGroup, FormHelperText, FormLabel, FormControl, InputLeftElement, Button, InputRightAddon, ResponsiveValue } from '@chakra-ui/react'
import { FaEye } from 'react-icons/fa'
import { colors, formColors } from '../../utils/colors'
import { field, inputHeight, inputWidth } from '../../utils/ui'
import { Textarea } from '@chakra-ui/react'

interface ITextFieldProps {
  placeholder?: string
  size?: ResponsiveValue<string> | undefined
  addClass?: string
  type?: 'text' | 'password' | 'number' | 'area'
  name?: string
  helperText?: string
  label?: string
  id?: string
  leftChildren?: React.JSX.Element | undefined | null
  rightChildren?: React.JSX.Element | undefined | null
  isDisabled?: boolean
  value?: string | number | undefined | null
  defaultValue?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: any
  onBlur?: any
  isInvalid?: boolean
  isRequired?: boolean
  limit?: number
  isReadOnly?: boolean
  onKeyDownCapture?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  trimOnChange?: boolean
}

const TextField = ({ placeholder, size = 'md', addClass, type = 'text', name, helperText, label, id, leftChildren, rightChildren, isDisabled, onChange, isInvalid = false, value, onFocus, onBlur, limit, isReadOnly, isRequired, onKeyDownCapture, trimOnChange }: ITextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (limit && e.target.value.length > limit) return
    if (trimOnChange) e.target.value = e.target.value.trim()
    else if (onChange) onChange(e)
  }
  return (
    <FormControl
      width={inputWidth}
      minH={field.inputContainerHeight}
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      <FormLabel opacity={0.5}>
        {label} {isRequired ? '*' : ''}
      </FormLabel>
      <InputGroup>
        {leftChildren && <InputLeftElement>{leftChildren}</InputLeftElement>}
        {['text', 'number', 'password'].includes(type) ? (
          <Input
            onKeyDownCapture={onKeyDownCapture}
            height={inputHeight}
            isReadOnly={isReadOnly}
            isInvalid={isInvalid}
            id={id}
            value={value ?? undefined}
            size={size}
            name={name}
            errorBorderColor={formColors.error.border}
            focusBorderColor={formColors.default.focus.border}
            className={addClass}
            placeholder={placeholder}
            type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
            isDisabled={isDisabled}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            _focus={{
              borderColor: 'none'
            }}
          />
        ) : (
          <Textarea
            onKeyDownCapture={onKeyDownCapture}
            height={inputHeight}
            isReadOnly={isReadOnly}
            isInvalid={isInvalid}
            id={id}
            value={value ?? undefined}
            name={name}
            resize='none'
            errorBorderColor={formColors.error.border}
            focusBorderColor={formColors.default.focus.border}
            className={addClass}
            placeholder={placeholder}
            isDisabled={isDisabled}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            _focus={{
              borderColor: 'none'
            }}
          />
        )}

        {type === 'password' && (
          <InputRightAddon
            backgroundColor={showPassword ? formColors.default.hover : colors.gray88}
            onClick={() => setShowPassword(!showPassword)}
            p='0'
          >
            <Button
              borderRightRadius='sm'
              borderTopLeftRadius='none'
              borderBottomLeftRadius='none'
              variant='solid'
              size='sm'
              minH='full'
              _hover={{
                backgroundColor: showPassword ? formColors.default.hover.backgroundColor : colors.gray88
              }}
              backgroundColor={showPassword ? formColors.default.hover.backgroundColor : colors.gray88}
              onClick={() => setShowPassword(!showPassword)}
            >
              <FaEye />
            </Button>
          </InputRightAddon>
        )}

        {rightChildren}
      </InputGroup>
      <FormHelperText height={2}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export { TextField }
