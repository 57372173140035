'use client'
import React from 'react'
import { Flex, SimpleGrid, useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from '../../hooks/useForm'
import { Button } from '../fragments/Button'
import { colors } from '../../utils/colors'
import { PhoneField } from '../fields/PhoneFIeld/PhoneField'
import { TextField, SelectField } from '../fields'
import { ProductType, UpdateUserForm, UserRole, UserRoleOptions } from '../../ts/interfaces/interfaces'
import { useAppSelector } from '../../store/hooks'
import { UserService } from '../../services/User'
import { InvoicesService } from '../../services/Invoices'
import { IOption } from '../fields/SelectField'
import { ConfirmDialog } from '../fragments/ConfirmDialog'
interface ITaxInformationForm {
  initialForm?: UpdateUserForm | null
  userRole?: UserRole
}

const UpdateUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone_number: Yup.string().min(6).required('Invalid phone number'),
  name: Yup.string().nullable(),
  tax_information: Yup.array(),
  password: Yup.string().nullable(),
  role: Yup.mixed<UserRole>().oneOf(Object.values(UserRole))
})

const CreateUserSchema = Yup.object().shape({
  email: Yup.string().email('').required('Required'),
  phone_number: Yup.string().min(6).required('Invalid phone number'),
  name: Yup.string(),
  tax_information: Yup.array(),
  password: Yup.string().required(),
  role: Yup.mixed<UserRole>().oneOf(Object.values(UserRole))
})

const USER_INITIAL_FORM = {
  email: '',
  phone_number: '',
  name: '',
  password: '',
  tax_information: [],
  role: null
}

type UserFormValues =
  | UpdateUserForm
  | {
      email: string
      phone_number: string
      name: string
      password: string
      tax_information: Array<number>
      role: null
    }

const UserForm = React.memo(({ initialForm, userRole }: ITaxInformationForm) => {
  const navigate = useNavigate()
  const subscription = useAppSelector((state) => state.subscription)
  const users = useAppSelector((state) => state.organization.users)
  const userAuth = useAppSelector((state) => state.user)
  const [taxInformationOptions, setTaxInformationOptions] = React.useState<IOption[]>([])
  const confirmDialogDisclosure = useDisclosure()

  const init = async () => {
    const taxInformationList = await InvoicesService().getAllTaxInformation()

    setTaxInformationOptions(
      taxInformationList.map((taxInfo) => ({
        label: taxInfo.tax_id.length === 13 ? `${taxInfo.first_name} ${taxInfo.surname}` : taxInfo.business_name,
        value: taxInfo.id
      }))
    )

    if (initialForm) {
      return
    }
    const seatProduct = subscription.products.find(({ type }) => type == ProductType.seat)
    const usersList = await UserService().getAllUsers()
    const enableUsers = usersList.data.filter(({ deleted_at }) => (!deleted_at ? true : false))

    if ((seatProduct && (seatProduct.limit?.max as number) <= enableUsers.length) || !seatProduct) {
      navigate(-1)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  const [formValues, setFormValues] = React.useState<UserFormValues | null>(null)

  const submit = async (values: UserFormValues) => {
    try {
      const values_ = {
        ...values,
        password: values.password ?? null
      }
      if (initialForm)
        await UserService().updateUser({
          id: initialForm.id,
          ...values_
        })
      else await UserService().createUser(values_)

      const message = initialForm ? 'Usuario actualizado exitosamente.' : 'Usuario invitado exitosamente.'

      toast({
        title: message,
        duration: 8000,
        isClosable: true
      })
      navigate(-1)
    } catch (error) {
      toast({
        title: 'Hubo un problema.',
        status: 'error',
        duration: 8000,
        isClosable: true
      })
    }
  }

  const form = useForm({
    initialValues: (initialForm ? initialForm : USER_INITIAL_FORM) as UserFormValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: initialForm ? UpdateUserSchema : CreateUserSchema,
    onSubmit: async (values) => {
      form.handleSetTouchedForm(values)

      if (values.password && initialForm) {
        setFormValues(() => values)
        confirmDialogDisclosure.onOpen()
      } else {
        await submit(values)
      }
    }
  })

  const toast = useToast()

  return (
    <>
      <form
        className='pt-[1rem] flex flex-col gap-y-[1rem] md:px-[2rem]'
        onSubmit={(e) => {
          e.preventDefault()
          form.handleSubmit()
        }}
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3
          }}
          spacing={{
            base: '1rem',
            md: '2rem'
          }}
          width='100%'
          placeItems={{
            base: 'center',
            md: 'end'
          }}
        >
          <TextField
            isRequired={false}
            label='Nombre'
            name='name'
            id='name'
            isDisabled={false}
            value={form.values.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={form.touched.name && form.errors.name ? true : false}
          />

          <TextField
            isReadOnly={form.isSubmitting || (userRole && userRole === UserRole.ADMIN)}
            isRequired={true}
            label='Email'
            name='email'
            id='email'
            isDisabled={false}
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={form.touched.email && form.errors.email ? true : false}
          />

          <PhoneField
            isReadOnly={form.isSubmitting}
            isRequired={true}
            label='Número de WhatsApp'
            name='phone_number'
            id='phone_number'
            placeholder='+'
            isDisabled={false}
            value={form.values.phone_number}
            onChange={(phone) => form.setFieldValue('phone_number', phone)}
            onBlur={form.handleBlur}
            isInvalid={form.touched.phone_number && form.errors.phone_number ? true : false}
          />

          <SelectField
            isDisabled={initialForm && userAuth.id === initialForm.id ? true : userRole === UserRole.ADMIN && users.filter((user) => user.role === UserRole.ADMIN && !user.deleted_at).length === 1 ? true : false}
            id='role'
            name='role'
            options={UserRoleOptions}
            onChange={form.handleSelectChange}
            label='Rol de usuario'
            value={form.values.role}
            isClearable={false}
            isSearchable={true}
            isInvalid={form.touched.role && form.errors.role ? true : false}
          />

          {initialForm && form.values.role === UserRole.ADMIN ? null : (
            <TextField
              id='password'
              name='password'
              value={form.values.password}
              label='Contraseña'
              isRequired={initialForm ? false : true}
              isInvalid={form.touched.password && form.errors.password ? true : false}
              onChange={form.handleChange}
              placeholder={initialForm ? '********' : ''}
            />
          )}

          {form.values.role != UserRole.ADMIN ? (
            <SelectField
              id='tax_information'
              name='tax_information'
              options={taxInformationOptions}
              onChange={form.handleSelectChange}
              label='Razones sociales'
              placeholder={form.values.tax_information.length > 0 ? '' : 'Todas'}
              value={form.values.tax_information}
              isClearable={false}
              isSearchable={false}
              isMulti={true}
            />
          ) : null}
        </SimpleGrid>

        <Flex
          w='100%'
          flexDir='column'
          justifyContent='center'
          alignItems='center'
          mt={{
            base: '1rem'
          }}
          gap='1rem'
        >
          <Button
            id='UserFormSubmitButton'
            isLoading={form.isSubmitting}
            type='submit'
          >
            {initialForm ? 'Actualizar usuario' : 'Agregar usuario'}
          </Button>
          <Button
            isLoading={form.isSubmitting}
            borderStyle='dashed'
            bg='transparent'
            borderColor={colors.text[4]}
            textColor={colors.text[2]}
            _hover={{
              borderColor: colors.text[1]
            }}
            _active={{
              bg: 'transparent'
            }}
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Flex>
      </form>
      <ConfirmDialog
        confirmMethod={() => submit(formValues as UserFormValues)}
        isOpen={confirmDialogDisclosure.isOpen}
        onClose={confirmDialogDisclosure.onClose}
        title='¿Estás seguro que quieres cambiar la contraseña establecida por el usuario?'
        cancelMessage='Cancelar'
        confirmMessage='Si'
      />
    </>
  )
})

export { UserForm }
