'use client'
import React from 'react'
import { CellContext } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { routerObj } from '../components/routing/Router'
import { Flex, Icon, useDisclosure, useBoolean } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import { PageLayout } from '../components/Layout/PageLayout'
import { Title } from '../components/fragments/Title'
import { Button } from '../components/fragments/Button'
import { useAppSelector } from '../store/hooks'
import { TaxInformation } from '../ts/interfaces/invoices'
import { InvoicesService } from '../services/Invoices'
import { ProductType } from '../ts/interfaces/interfaces'
import { ConfirmDialog } from '../components/fragments/ConfirmDialog'
import { TaxInformationTable, TableData } from '../components/ui/TaxInformationTable'

const TaxInformationDashboardPage = () => {
  const navigate = useNavigate()
  const surpassedLimitModal = useDisclosure()
  const deleteTaxInformationModal = useDisclosure()

  const [targetDeleteTaxInformation, setTargetDeleteTaxInformation] = React.useState<TaxInformation['id'] | null>(null)
  const [isLoading, toggleIsLoading] = useBoolean(false)

  const subscription = useAppSelector((state) => state.subscription)
  const taxInformationList = useAppSelector((state) => state.invoices.taxInformation)
  const totalEnabledTaxInformation = React.useMemo(() => {
    return taxInformationList.filter(({ deleted_at }) => (!deleted_at ? true : false))
  }, [taxInformationList])

  const taxInformationLimit: number = React.useMemo(() => {
    const taxInformationProduct = subscription.products.find(({ type }) => type == ProductType.tax_information)

    return taxInformationProduct ? (taxInformationProduct.limit?.max as number) : 1
  }, [subscription])

  const initData = async () => {
    toggleIsLoading.on()
    await InvoicesService().getAllTaxInformation()
    toggleIsLoading.off()
  }

  const handleDeleteTaxInformation = React.useCallback(async () => {
    await InvoicesService({ useLoader: true }).deleteTaxInformation(targetDeleteTaxInformation as TaxInformation['id'])
    setTargetDeleteTaxInformation(null)
    await initData()
  }, [targetDeleteTaxInformation, setTargetDeleteTaxInformation, InvoicesService])

  const handleEnableTaxInformation = React.useCallback(
    async (userId: TaxInformation['id']) => {
      if (totalEnabledTaxInformation.length >= taxInformationLimit) {
        return surpassedLimitModal.onOpen()
      } else {
        await InvoicesService({ useLoader: true }).enableTaxInformation(userId)
        await initData()
      }
    },
    [taxInformationLimit, surpassedLimitModal, totalEnabledTaxInformation]
  )

  React.useEffect(() => {
    initData()
  }, [])

  const handleAddTaxInformation = React.useCallback(() => {
    const taxInformationProduct = subscription.products.find(({ type }) => type == ProductType.tax_information)
    const enabledTaxInformation = taxInformationList.filter(({ deleted_at }) => (!deleted_at ? true : false))

    if ((taxInformationProduct && (taxInformationProduct.limit?.max as number) <= enabledTaxInformation.length) || (!taxInformationProduct && enabledTaxInformation.length)) {
      surpassedLimitModal.onOpen()
      return
    }

    navigate(routerObj.createTaxInformation.cleanParamsPath as string)
  }, [navigate, routerObj, subscription, taxInformationList, surpassedLimitModal])

  const handleDeleteIconButton = (data: CellContext<TableData, string | undefined>) => {
    if (data.row.original.deleted_at) {
      handleEnableTaxInformation(data.row.original.id)
    } else {
      setTargetDeleteTaxInformation(data.row.original.id)
      deleteTaxInformationModal.onOpen()
    }
  }

  return (
    <PageLayout>
      <Flex
        flexDir={{
          base: 'column',
          md: 'row'
        }}
        mb='1rem'
        alignItems='center'
        justifyContent={{
          base: '',
          md: 'space-between'
        }}
      >
        <Title>Razones Sociales</Title>
        <Button
          width='fit-content'
          onClick={handleAddTaxInformation}
          gap='0.4rem'
          id='addTaxInformationButton'
        >
          Agregar Razón Social{' '}
          <Icon
            aria-label='agregar grupo'
            as={FaPlus}
          />
        </Button>
      </Flex>

      <TaxInformationTable
        isLoading={isLoading}
        handleDeleteIconButton={handleDeleteIconButton}
      />

      <ConfirmDialog
        isOpen={surpassedLimitModal.isOpen}
        onClose={surpassedLimitModal.onClose}
        title={`Tu plan actual solo permite ${taxInformationLimit > 1 ? `${taxInformationLimit} razones sociales` : '1 razón social'}.  Si quieres contratar una Razón Social adicional por favor contáctanos.`}
        confirmMethod={() => {
          window.open(process.env.REACT_APP_HELP_URL, '_blank')
        }}
        cancelType='red'
        confirmType='blue'
        confirmMessage='Contactar'
        cancelMessage='Cancelar'
      />

      <ConfirmDialog
        isOpen={deleteTaxInformationModal.isOpen}
        onClose={deleteTaxInformationModal.onClose}
        title={'¿Quieres deshabilitar esta razón social?'}
        message='Al deshabilitarla, tus usuarios ya no podrán facturar a esta razón social. Puedes volver a activarla en cualquier momento.'
        confirmMethod={handleDeleteTaxInformation}
        cancelType='blue'
        confirmType='red'
        confirmMessage='Deshabilitar'
        cancelMessage='Cancelar'
      />
    </PageLayout>
  )
}

export { TaxInformationDashboardPage }
